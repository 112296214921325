import axios from 'axios';

let baseURL = process.env.VUE_APP_API_URL
if (process.env.NODE_ENV !== 'production') {
  baseURL = process.env.VUE_APP_API_URL_DEV
}
const instance = axios.create({
  baseURL,
})

export { instance, baseURL }
