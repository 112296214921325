<template>
  <div class="home-wrap">
    <!-- <div
      class="absolute-wrapper"
      v-if="showModal || showSuccessToast || showIMEI"
    ></div>
    <IMEImodal v-if="showIMEI" @closeIMEIModal="handlecloseIMEIModal" />
    <HomeModalComponent
      v-if="showModal"
      @closeModal="handleCloseModal"
      @showSuccess="handleShowSuccess"
    />
    <HomeModalToastSuccess
      v-if="showSuccessToast"
      @closeModal="handleCloseSuccessModal"
    />
    <div class="top">
      <div class="wrapper">
        <img class="logo" src="@/assets/xiaomi_logo.png" alt="" />
      </div>
    </div>
    <div class="bottom">
      <div class="wrapper">
        <div class="present">
          <img class="image-phones" src="@/assets/xiaomi-3-phones.svg" alt="" />
          <div class="mobile-image-div">
            <img class="mobile-image" src="@/assets/mobile-image.svg" alt="" />
          </div>
        </div>
        <div class="bottom-right">
          <img class="mobile-gift" src="@/assets/poklon.svg" alt="" />
          <div class="mobile-image-gift">
            <img class="gift-mobile" src="@/assets/gift-mobile.svg" alt="" />
          </div>
          <div class="models hide">
            <span> Kupili ste </span>
            <img src="../assets/first.png" alt="" />
          </div>
          <div class="apply-for hide">
            <span> Prijavite se za </span> <br />
            <img src="../assets/new-prize.svg" alt="" />
          </div>
          <div class="button-container">
            <button class="choose-gift" @click="showModal = !showModal">
              {{ $t("home.applyBtn") }}
            </button>
              <button class="rules" @click="handleShowIMEI">
                Kako do IMEI broja?
              </button>
          </div>
          <div class="support-mail">
            <span>Ukoliko imate problem sa prijavom obratite nam se na</span>
            <br />
            <span>xiaomi.prijava@gmail.com</span>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// import HomeModalComponent from "../components/HomeModalComponent.vue";
// import HomeModalToastSuccess from "../components/HomeModalToastSuccess.vue";
// import IMEImodal from "../components/IMEImodal.vue";
export default {
  components: {
    // HomeModalComponent,
    // HomeModalToastSuccess,
    // IMEImodal
  },
  data() {
    return {
      showModal: false,
      showSuccessToast: false,
      showIMEI: false,
    };
  },
  methods: {
    handleCloseModal() {
      this.showModal = false;
    },
    handleCloseSuccessModal() {
      this.showSuccessToast = false;
    },
    handleShowSuccess() {
      this.showModal = false;
      this.showSuccessToast = true;
    },
    handleShowIMEI() {
      this.showIMEI = true;
    },
    handlecloseIMEIModal() {
      this.showIMEI = false;
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  position: relative;
  background: #ededed;
  .absolute-wrapper {
    height: 100%;
    width: 100%;
    background: #fff;
    opacity: 0.9;
    z-index: 10;
    position: absolute;
  }
  .top {
    // background: url("../assets/11tbackground.png") no-repeat;
    background-size: cover;
  }
  .bottom {
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .bottom-right {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .gift-mobile {
          display: none !important;
        }
        h2 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: bold;
          font-size: 60px;
          line-height: 73px;
          text-align: center;
          letter-spacing: -0.01em;
          color: #22211f;
          margin: 0 0 170px 0;
          width: 380px;
        }
      }
    }
  }
  .wrapper {
    max-width: 1920px;
    margin: 0 auto;
    padding: 100px 80px 0 80px;
    background-repeat: no-repeat;
    .logo {
      display: block;
      width: 88px;
      height: 88px;
      margin: 0 0 45px calc(100% - 88px);
    }
    .warranty-wrap {
      margin: 0 0 30px 0;
    }
    .logo-title-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        font-family: Xiaomi-Bold;
        font-size: 60px;
        line-height: 73px;
        letter-spacing: -0.01em;
        width: 50%;
        color: #22242c;
      }
    }
    & > p {
      font-family: Xiaomi-Regular;
      font-size: 32px;
      line-height: 27px;
      color: #4c4e52;
      margin: 40px 0 100px 0;
    }
    .flex-wrap {
      // height: 700px;
      display: flex;
      justify-content: space-between;
      img {
        display: inline-block;
        width: 450px;
      }
      .phone-model-data {
        border-left: 4px solid #e7e7e7;
        padding: 0 9px;
        h2 {
          font-family: Xiaomi-Regular;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 31px;
          color: #212121;
        }
        p {
          font-family: Xiaomi-Regular;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 31px;
          color: #ff6900;
        }
      }
      .flex-wrap-left {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        img {
          max-width: 580px;
        }
        .phone-model-data {
          align-self: center;
          transform: translateX(-75%);
          margin-bottom: 35px;
        }
      }
      .flex-wrap-right {
        align-self: flex-start;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
          max-width: 580px;
        }
      }
    }
    .present {
      position: relative;
      max-width: 610px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        display: inline-block;
        max-width: 500px;
        margin: 0 auto;
      }
    }
    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin: 0 auto;
      button {
        width: 201px;
        height: 61px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-family: Xiaomi-Regular;
        border: none;
        outline: none;
        cursor: pointer;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
      }
      .choose-gift {
        background: #ff6900;
        color: #ffffff;
      }
      .rules {
        background: #fff;
        color: #ff6900;
      }
    }
  }
  .support-mail {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
      display: inline-block;
      text-align: center;
    }
    span:nth-of-type(2) {
      color: #ff6900;
    }
  }
}
// @media only screen and (max-width: 1166px) {
//   .bottom {
//     .wrapper {
//       flex-direction: column;
//       padding: 40px 120px 120px 120px;
//       .bottom-right {
//         h2 {
//           margin: 0 0 50px 0 !important;
//         }
//       }
//       .present{
//         img{
//           display: none;
//         }
//       }
//     }
//   }
// }
@media only screen and (max-width: 1080px) {
  .home {
    .wrapper {
      .logo {
        margin: 0 auto;
      }
      .warranty-wrap {
        text-align: center;
        margin: 0 0 50px 0;
      }
      .logo-title-wrap {
        flex-direction: column-reverse;
        h1 {
          text-align: center;
          width: 100%;
          margin-top: 40px;
        }
      }
      & > p {
        text-align: center;
      }
      .flex-wrap {
        flex-direction: column;
        height: unset;
        img {
          max-width: 400px;
        }
        .flex-wrap-left {
          width: unset;
          align-self: center;
          justify-content: center;
          margin-bottom: 75px;
          .phone-model-data {
            transform: none;
          }
        }
        .flex-wrap-right {
          width: unset;
          align-self: center;
          margin-bottom: 75px;
        }
      }
      .button-container {
        margin: 100px 0 0 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .home {
    .wrapper {
      padding: 40px;
      .logo-title-wrap {
        h1 {
          font-size: 40px;
          line-height: 40px;
        }
      }
      & > p {
        font-size: 20px;
        margin: 40px 0 100px 0;
      }
    }
    .button-container {
      button {
        font-size: 15px !important;
      }
    }
    .bottom {
      .wrapper {
        .bottom-right {
          h2 {
            font-size: 40px;
            margin: 0 !important;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .top {
    .flex-wrap {
      img {
        max-width: 100% !important;
      }
    }
  }
  .bottom {
    .wrapper {
      h2 {
        max-width: 360px;
      }
      .present {
        img {
          max-width: 100%;
        }
      }
      .bottom-right {
        img {
          display: none;
        }
        .gift-mobile {
          display: block !important;
          width: 100px;
          height: 100px;
        }
      }
    }
    .button-container {
      button {
        width: 127.04px !important;
        height: 38.55px !important;
        font-family: "Roboto" !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 11px !important;
        letter-spacing: 0.07em !important;
      }
    }
    .support-mail {
      max-width: 100%;
    }
  }
}

.home-wrap {
  max-width: 1920px;
  margin: 0 auto;
  padding: 15px 200px 0 200px;
  background-repeat: no-repeat;
  background-color: #ededed;
  position: relative;
  .absolute-wrapper {
    height: 100%;
    width: 100%;
    background: #fff;
    opacity: 0.9;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
  }
  .logo {
    display: block;
    width: 80px;
    margin: 0 0 15px calc(100% - 0px);
  }
  .present {
    max-width: 610px;
    img {
      display: block;
      width: 100%;
    }
    .mobile-image-div {
      .mobile-image {
        display: none;
        //ovde
      }
    }
  }
  .bottom {
    .wrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin: 0 auto;
        button {
          width: 201px;
          height: 61px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          font-family: Xiaomi-Regular;
          border: none;
          outline: none;
          cursor: pointer;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          margin: 0 10px 0 10px;
        }
        .choose-gift {
          background: #ff6900;
          color: #ffffff;
        }
        .rules {
          background: #fff;
          color: #ff6900;
        }
      }
      .bottom-right {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 35px;
        .gift-mobile {
          display: none;
        }
        .models {
          font-family: Roboto;
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 121.69%;
          color: #000;
          display: flex;
          align-items: center;
          margin: 0 0 70px 0;
          img {
            display: block;
            margin: 0 10px;
          }
        }
        .apply-for {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 121.69%;
          color: #000;
          align-self: baseline;
          margin: 0 0 100px 0;
          img {
            display: block;
            max-width: 400px;
          }
        }
      }
    }
  }
  .support-mail {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
      display: inline-block;
      text-align: center;
    }
    span:nth-of-type(2) {
      color: #ff6900;
    }
  }
}
@media only screen and (max-width: 1600px) {
  .home-wrap {
    max-width: 1920px;
    margin: 0 auto;
    // padding: 50px 90px 0 90px;
    background-repeat: no-repeat;
    background-color: #ededed;
    position: relative;
    .absolute-wrapper {
      height: 100%;
      width: 100%;
      background: #fff;
      opacity: 0.9;
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
    }
    .logo {
      display: block;
      width: 80px;
      margin: 0 0 15px calc(100% - 0px);
    }
    .present {
      max-width: 610px;
      img {
        display: block;
        width: 100%;
      }
    }
    .bottom {
      .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .button-container {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          margin: 0 auto;
          button {
            width: 201px;
            height: 61px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            font-family: Xiaomi-Regular;
            border: none;
            outline: none;
            cursor: pointer;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
          }
          .choose-gift {
            background: #ff6900;
            color: #ffffff;
          }
          .rules {
            background: #fff;
            color: #ff6900;
          }
        }
        .bottom-right {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .models {
            font-family: Roboto;
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 121.69%;
            color: #000;
            display: flex;
            align-items: center;
            margin: 0 0 70px 0;
            img {
              display: block;
              margin: 0 10px;
            }
          }
          .apply-for {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 121.69%;
            color: #000;
            align-self: baseline;
            margin: 0 0 100px 0;
            img {
              display: block;
              max-width: 400px;
            }
          }
        }
      }
    }
    .support-mail {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      span {
        display: inline-block;
        text-align: center;
      }
      span:nth-of-type(2) {
        color: #ff6900;
      }
    }
  }
}

@media only screen and (max-width: 1540px) {
  .models {
    // font-size: 30px !important;
    // line-height: 100% !important;
    justify-content: center;
    img {
      // width: 20%;
      // align-self: center;
    }
  }
  .apply-for {
    margin: 0 auto 68px auto !important;
  }
}

// @media only screen and (max-width: 1166px) {
//   .home-wrap {
//     padding: 50px 0 0 0 !important;
//     .bottom {
//       .wrapper {
//         margin: 0;
//         padding: 0;
//       }
//     }
//     .bottom-right {
//       margin: 30px 0 0 0;
//       .hide{
//         display: none !important;
//       }
//       img {
//         margin: 0 0 30px 0;
//       }
//     }
//     .support-mail {
//       text-align: center;
//     }
//     .present {
//     img {
//     }
//     .mobile-image{
//       display: block;
//       //ovde
//     }
//   }
//   }
// }

@media only screen and (max-width: 800px) {
  .logo {
    width: 60px !important;
  }
}
@media only screen and (max-width: 768px) {
  .apply-for {
    font-size: 40px !important;
    margin: 0 auto 30px auto !important;
    img {
      max-width: 300px !important;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .home-wrap {
    padding: 0px 50px 0px 50px;
    .top {
      // display: none;
      .logo {
        margin: 0 0 15px calc(100% - 60px);
      }
    }
    .bottom {
      .wrapper {
        // flex-direction: column;
        .present {
          .image-phones {
            // display: none;
          }
          .mobile-image-div {
            .mobile-image {
              // display: block;
              // margin: 0 0 20px 0;
            }
          }
        }
        .bottom-right {
          // width: 100%;
          // background-color: white;
          .mobile-gift {
            // display: none;
          }
          .mobile-image-gift {
            .gift-mobile {
              // display: block;
              // margin: 10px 0 20px 0;
            }
          }
          .hide {
            // display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .home-wrap {
    padding: 0px 0px 0px 0px;
    .top {
      display: none;
    }
    .bottom {
      .wrapper {
        flex-direction: column;
        .present {
          .image-phones {
            display: none;
          }
          .mobile-image-div {
            .mobile-image {
              display: block;
              margin: 0 0 20px 0;
            }
          }
        }
        .bottom-right {
          width: 100%;
          background-color: white;
          margin-bottom: 0px;
          .mobile-gift {
            display: none;
          }
          .mobile-image-gift {
            .gift-mobile {
              display: block;
              margin: 10px 0 20px 0;
            }
          }
          .hide {
            display: none;
          }
        }
      }
    }
  }
}
</style>
