<template>
  <div class="pagination">
    <div class="left" @click="refresh">
      <img src="@/assets/icons/return_icon.svg" alt="refresh" />
      <span> {{$t("pagination.refreshList")}} </span>
    </div>
    <div class="right">
      <span class="currently-displayed">{{ calc ? total : calc ? total : 1 + numeration * 10 }} - {{calc ? total : 10 + numeration * 10}}</span
      >
      <span class="total">{{$t("pagination.total")}}: ({{ total }})</span>
      <span class="controls">
        <span class="material-icons" @click="clicked('-')"> chevron_left </span>
        <span class="material-icons" @click="clicked('+')">
          chevron_right
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
    },
    resetPaginationBool: {
      type: Boolean,
    },
  },
  data() {
    return {
      numeration: 0,
      totalIsMax: false,
    };
  },
  watch: {
    resetPaginationBool(val) {
      this.numeration = 0;
      this.$emit("resetPagination", false);
    },
  },
  computed: {
    calc() {
      return this.total - this.numeration * 10 - 10 < 0;
    },
  },
  methods: {
    refresh() {
      this.$emit("refreshWithFilter");
    },
    clicked(payload) {
      if (payload == "+" && this.calc) {
        this.totalIsMax = true;
        return;
      }
      if (payload == "+") {
        this.numeration += 1;
      } else if (payload == "-") {
        if (this.numeration == 0) return;
        this.numeration -= 1;
      }
      // console.log(this.numeration * 10, "ovo je offset");
      this.$emit("offset", this.numeration * 10);
    },
  },
};
</script>

<style lang='scss' scoped>
.pagination {
  padding: 20px 40px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    cursor: pointer;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 20px;
      color: #585e6d;
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    .currently-displayed {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #394050;
    }
    .total {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #9da0a6;
      margin: 0 20px 0 10px;
    }
    .controls {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #6a717d;
      cursor: pointer;
      span {
        &:hover {
          color: #9da0a6;
        }
      }
    }
  }
}
</style>