<template>
  <div id="home-page">
    <div class="logo-container">
      <img src="../assets/xiaomi_logo.png" alt="xiaomi_logo" />
    </div>
    <LoginModal />
  </div>
</template>

<script>
import LoginModal from "../components/LoginModal.vue";
export default {
  name: "Login",
  components: {
    LoginModal,
  },
};
</script>

<style lang="scss" scoped>
#home-page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .logo-container {
    margin-bottom: 20px;
    img {
      display: inline-block;
      width: 60px;
    }
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    color: $text-gray;
    margin-top: 40px;
    span {
      color: $orange;
    }
  }
}
</style>
