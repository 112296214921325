<template>
  <div class="confirmation-modal">
    <div class="top">
      <img :src="getImg" alt="" />
    </div>
    <div class="bottom">
      <div class="info">
        <div class="info-wrap">
          <div>{{$t('halfScreenModal.address')}}</div>
          <div class="info-data">{{ ticketData.frm_address }}</div>
        </div>
        <div class="info-wrap">
          <div>{{$t('halfScreenModal.city')}}</div>
          <div class="info-data">
            {{ ticketData.frm_place }}
          </div>
        </div>
        <div class="info-wrap">
          <div>{{$t('halfScreenModal.zipCode')}}</div>
          <div class="info-data">
            {{ ticketData.frm_ponumber }}
          </div>
        </div>
        <div class="info-wrap">
          <div>{{$t('halfScreenModal.phone')}}</div>
          <div class="info-data">
            {{ ticketData.frm_phone }}
          </div>
        </div>
        <div class="info-wrap">
          <div>{{$t('halfScreenModal.email')}}</div>
          <div class="info-data">
            {{ ticketData.frm_email }}
          </div>
        </div>
      </div>
      <div class="controls">
        <div class="input-wrap">
          <label for="confirm-dropdown">{{$t('halfScreenModal.status')}}</label>
          <select
            @change="submitStatus"
            id="confirm-dropdown"
            v-model="approvalStatus"
          >
            <option value="1">{{$t('halfScreenModal.confirmed')}}</option>
            <option value="0">{{$t('halfScreenModal.rejected')}}</option>
          </select>
        </div>
        <div class="selected-gift-wrap">
          <label>{{$t('halfScreenModal.gift')}}</label>
          <div class="selected-gift">
            {{gift}}
          </div>
        </div>
        <span class="checkbox-wrap">
          <input
            type="checkbox"
            v-model="checkBoxBool"
            @change="openConfirmModal"
            ref="checkbox"
          />
          <span> {{$t('halfScreenModal.sent')}} </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from "../../api/config";
import { mapState } from "vuex";
import api from "@/api";
export default {
  props: {
    ticketData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapState(["sid"]),
    getImg() {
      const sid = this.sid ? this.sid : JSON.parse(localStorage.getItem("sid"));
      return `${baseURL}/submission/${this.ticketData.frm_id}/receipt?sid=${sid}&frm_id=${this.ticketData.frm_id}`;
    },
  },
  data() {
    return {
      approvalStatus: null,
      checkBoxBool: false,
    };
  },
  watch: {
    ticketData: {
      deep: true,
      handler(val) {
        this.init();
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async submitStatus() {
      try {
        const res = await api.submitSingleTicket({
          id: this.ticketData.frm_id,
          sid: this.sid ? this.sid : JSON.parse(localStorage.getItem("sid")),
          approval_status: this.approvalStatus,
        });
        this.$emit("refresh");
      } catch (error) {
        this.approvalStatus = this.ticketData.frm_approval_status
        this.$vToastify.error(error.response.data.message, "Error");
      }
    },
    openConfirmModal() {
      const obj = {
        id: this.ticketData.frm_id,
        delivery_status: this.checkBoxBool ? 1 : 0,
      };
      console.log(obj);
      this.$emit("showConfirmBool", obj);
    },
    init() {
      if (this.ticketData.frm_deliverystatus_at) {
        this.checkBoxBool = true;
      } else {
        this.checkBoxBool = false;
      }
      if (
        this.ticketData.frm_approval_status == 0 ||
        this.ticketData.frm_approval_status == 1
      ) {
        this.approvalStatus = this.ticketData.frm_approval_status;
      } else {
        this.approvalStatus = null;
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.confirmation-modal {
  width: 50%;
  position: absolute;
  height: 662px;
  top: 5.5%;
  right: 0;
  padding: 20px;
  background: #f9f9fa;
  .top {
    width: 100%;
    height: 50%;
    height: 380px;
    margin-bottom: 30px;
    overflow: auto;
    img {
      display: block;
      width: 100%;
    }
  }
  .bottom {
    .info {
      display: flex;
      align-items: center;
      justify-content: space-around;
      & > div {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #81858f;
        margin-bottom: 10px;
      }
      .info-data {
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        color: #394050;
        margin-top: 10px;
      }
    }
  }
  .controls {
    margin-top: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #585e6d;
    }
    .selected-gift-wrap {
      height: 100%;
      margin: 0 20px;
      span {
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    .input-wrap {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-right: 25px;

      select {
        width: 281px;
        height: 54px;
        background: #ffffff;
        border: 1px solid #e2e2e2;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        color: #ff6700;
        &:focus {
          outline: none;
        }
      }
    }
    .checkbox-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        width: 24px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #c6c6c6;
        box-sizing: border-box;
        border-radius: 5px;
        margin-right: 10px;
      }
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        color: #394050;
      }
    }
  }
}
</style>