<template>
  <div class="confirmation-modal">
    <div class="dialog">
      <h2>{{$t("confirmationModal.sentConfirmation")}}</h2>
      <p v-if="apiData.delivery_status == 1">{{$t("confirmationModal.confirmSent")}}</p>
      <p v-if="apiData.delivery_status == 0">{{$t("confirmationModal.confirmNotSent")}}</p>
      <div class="button-wrapper">
        <button class="confirm-btn" @click="confirmSend">{{$t("confirmationModal.confirmBtn")}}</button
        ><button class="cancel-btn" @click="closeModal">{{$t("confirmationModal.rejectBtn")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";
export default {
  props: {
    apiData: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["sid"]),
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    confirmSend() {
      this.submitSingleTicket();
    },
    async submitSingleTicket() {
      try {
        const res = await api.submitSingleTicket(this.apiData);
        console.log(res);
      } catch (error) {
        this.$vToastify.error(error.response.data.message, "Error");
      } finally {
        this.$emit("closeModal");
      }
    },
  },
  mounted() {
    console.log(this.apiData)
  },
};
</script>

<style lang='scss' scoped>
.confirmation-modal {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background: #fff;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .dialog {
    width: 473px;
    height: 273px;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
      color: #394050;
      margin: 20px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #394050;
      margin-bottom: 40px;
    }
    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .confirm-btn {
        width: 145px;
        height: 54px;
        background: #f96600;
        border-radius: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.01em;
        border: none;
        color: #ffffff;
        cursor: pointer;
      }
      .cancel-btn {
        width: 145px;
        border: none;
        height: 54px;
        background: #fff;
        border-radius: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #f96600;
        cursor: pointer;
      }
    }
  }
}
</style>